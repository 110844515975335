
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { listMedalUser, deleteMedalUser, saveMedalUser, searchMedal } from "@/api/request/medal";

//组件
@Component({
  name: "MedalSend",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private medalList: any[] = [];
  private listLoading: boolean = true;

  //请求参数
  private listQuery = {
    //页码数据
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "", //用户ID
    medal_id_str: "", //勋章ID
    notice_id_str: "", //公告ID
    blogger_id_str: "", //博主ID
    medal_cat_id_str: "", //勋章分类ID

    //请求数据
    user_id: 0, //用户ID
    medal_id: 0, //勋章ID
    notice_id: 0, //公告ID
    blogger_id: 0, //博主ID
    medal_cat_id: 0, //勋章分类ID
  };

  //创建
  created() {
    //获取勋章列表
    this.getMedalList();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);
    this.listQuery.medal_id = Number(this.listQuery.medal_id_str);
    this.listQuery.notice_id = Number(this.listQuery.notice_id_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);
    this.listQuery.medal_cat_id = Number(this.listQuery.medal_cat_id_str);

    //获取数据
    const { data } = await listMedalUser(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取勋章列表
  private async getMedalList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await searchMedal({ page_size: 200, id: 0, medal_cat_id: 0, name: "" });

    //数据赋值
    this.medalList = data.list;
    this.medalList.unshift({ id: 0, name: "请选择勋章" });
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理创建
  private handleCreate(): void {
    //数据赋值
    this.addForm = {
      //临时数据
      notice_id_str: "",

      //基本信息
      users: [], //用户列表
      medal_id: 0, //勋章ID
      notice_id: 0, //公告ID
    };
    this.userList = [{ user_id_str: "", blogger_id_str: "" }];

    //显示对话框
    this.dialogVisible = true;
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    this.$confirm(`确定删除勋章发放？`, "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //请求删除
        const res: any = await deleteMedalUser({ id: row.id });

        //删除成功
        if (res.status == 0) {
          //显示提示
          this.$message.success("删除成功");

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private userList: any[] = [];
  private dialogVisible: boolean = false;

  //添加表单
  private addForm: any = {
    //临时数据
    notice_id_str: "",

    //基本信息
    medal_id: 0, //勋章ID
    notice_id: 0, //公告ID
    users: [], //用户列表
  };

  //添加发放对象
  private addUsers(): void {
    //数据赋值
    this.userList.push({ user_id_str: "", blogger_id_str: "" });
  }

  //创建勋章发放
  private async createData() {
    //数据赋值
    this.addForm.users = [];
    for (var i: number = 0; i < this.userList.length; i++) {
      //判断赋值
      if (Number(this.userList[i].user_id_str) != 0 && Number(this.userList[i].blogger_id_str) != 0) {
        //数据赋值
        this.addForm.users.push({ user_id: Number(this.userList[i].user_id_str), blogger_id: Number(this.userList[i].blogger_id_str) });
      }
    }

    //数据赋值
    this.userList = [];
    for (var i: number = 0; i < this.addForm.users.length; i++) {
      this.userList.push({ user_id_str: this.addForm.users[i].user_id, blogger_id_str: this.addForm.users[i].blogger_id });
    }

    //判断返回
    if (this.addForm.medal_id == 0) return Message({ message: "请选择发放勋章", type: "error", duration: 5 * 1000 });
    if (this.addForm.users.length == 0) return Message({ message: "请添加发放对象", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.notice_id = this.addForm.notice_id_str == "" ? undefined : Number(this.addForm.notice_id_str);

    //添加
    await saveMedalUser(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();
  }

  //删除用户
  private delUserRow(index: number): void {
    //删除数据
    if (this.userList[index]) {
      this.userList.splice(index, 1);
    }
  }
}
